import axios from "axios";
import React, { useState, useEffect,useContext } from "react";
import { UnifyButton } from '@deluxe/unify-button';
import { API_ROOT } from "../../ApiConfig/apiConfig";
import "./customReport.css";
import { TreeTable } from "primereact/treetable";
import { Column } from "primereact/column";
import { LoadingSpinner } from "@deluxe/unify-loading-spinner";
import _ from "lodash";
import * as ReportUtils from "./customReportUtilities";
import { Button } from 'react-bootstrap';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { TokenContext } from "../../Token/tokenContext";

function ReportAccounts({ accountParams, groupId, periodSourceHeader, suppressZeros, addFooter, periodMetadata, setShowReport, setShowAccount,header }) {
  const { token } = useContext(TokenContext); // Access token, fdic, bankId, username from TokenContext
  let foundNode = false;
  const apiOptions = { headers: { Authorization: `Bearer ${token}` } };
  const [account, setAccount] = useState(null);
  const [nodes, setNodes] = useState(null);
  const [columns, setColumns] = useState(null);
  const [accountLoading, setAccountLoading] = useState(true);
  const [loading, setLoading] = useState(false);

  const loadChildren = async (accountNumber, bankId) => {
    try {
      let result = null;
      if (accountParams) {
        result = await axios.get(`${API_ROOT.glServiceEndpoint}/api/report/profitcenters?accountNumber=${accountNumber}&bankId=${bankId}${accountParams}`, apiOptions);
        return result.data;
      };
      return null;

    } catch (error) {
      console.error(error);
    }
  };

  const getUpdatedNodes = (childNodes, nodeKey, value, expanded) => {
    let _childNodes = childNodes.map((childNode) => {
      if (!foundNode) {
        if (childNode.key === nodeKey) {
          childNode = value;
          childNode.expanded = expanded;
          foundNode = true;
        } else if (childNode.children && childNode.children.length > 0 && !foundNode) {
          childNode.children = getUpdatedNodes(childNode.children, nodeKey, value, expanded);
        }
      }
      return childNode;
    });
    return _childNodes;
  };

  const loadProfitCenterNode = (children, dataColumns, isGroupView) => {
    let node = {
      id: children.id,
      key: children.id.toString(),
      lineItemNumber: children.lineItemNumber,
      lineItemCategory: children.lineItemCategory,
      lineItemType: children.lineItemType,
      leaf: true,
      data: ReportUtils.buildNodeData(children, dataColumns, isGroupView),
    };
    return node;
  };
  const onExpand = async (event) => {
    if (!event.node.leaf) {
      let lazyNode = _.cloneDeep(event.node);
      let totNode = _.cloneDeep(event.node);
      lazyNode.data = { name: event.node.data.name };
      totNode.id = "tot" + totNode.id;
      totNode.key = "tot" + totNode.key;
      totNode.leaf = true;
      totNode.expanded = false;
      totNode.className = "bd-group-total-row";
      totNode.data.name = "Totals";
      totNode.lineItemCategory = 3;
      if (lazyNode.id && lazyNode.bankId) {
        if (!event.node.children) {
          setLoading(true);
          let profitCenterData = await loadChildren(lazyNode.id, lazyNode.bankId);
          lazyNode.children = [];
          let profitCenterChildren = profitCenterData.lineItems[0].children;
          profitCenterChildren.forEach((childData) => {
            if (!childData.suppressZero) {
              lazyNode.children.push(loadProfitCenterNode(childData, columns, false));
            }
          });
        }
        lazyNode.children.push(totNode);
      }
      let _nodes = nodes.map((node) => {
        if (!foundNode) {
          if (node.key === event.node.key) {
            node = lazyNode;
            node.expanded = true;
            foundNode = true;
          } else if (node.children && node.children.length > 0 && !foundNode) {
            node.children = getUpdatedNodes(node.children, event.node.key, lazyNode, true);
          }
        }
        return node;
      });

      foundNode = false;
      setLoading(false);
      setNodes(_nodes);
    }
  };

  const onCollapse = (event) => {
    let collapsedNode = _.cloneDeep(event.node);
    let collapsedNodeName = event.node.data.name;
    let collapsedNodeData = event.node.children[event.node.children.length - 1].data;
    collapsedNodeData.name = collapsedNodeName;
    collapsedNode.data = collapsedNodeData;
    collapsedNode.children.pop();
    let _nodes = nodes.map((node) => {
      if (!foundNode) {
        if (node.key === event.node.key) {
          node = collapsedNode;
          node.expanded = false;
          foundNode = true;
        } else if (node.children && node.children.length > 0 && !foundNode) {
          node.children = getUpdatedNodes(node.children, event.node.key, collapsedNode, false);
        }
      }
      return node;
    });
    foundNode = false;
    setNodes(_nodes);
  };

  const goBack = () => {
    setShowReport(true);
    setShowAccount(false);
    setAccount(null);
  }

  const generatePDF = () => {
    const firstRow = nodes[0];
    const firstRowName = firstRow.data.name;
    const footerText = "Rows with zero balances are suppressed";
    const exportHeader = GetExportPDFHeader(account.accountHeader.periodValues, periodMetadata);
    const exportData = convertExportData(nodes);
    const exportBody = formatExportPDFBodyData(exportData.slice(1));
    // const bodyData = exportBody.slice(1);
    const doc = new jsPDF('landscape');
    doc.setFontSize(14);
    doc.text(account.title.toString(), 14, 15);
    doc.setFontSize(12);
    doc.text(account.subtitle.toString(), 14, 22);
    doc.setFontSize(8);
    // doc.text(account.accountHeader.periodSourceHeader.toString(), 75, 29);
    doc.autoTable({
      startY: 29,
      head: [[firstRowName, ...exportHeader[0].slice(1)]],
      body: exportBody,
      styles: {
        fontSize: 6,
        valign: 'middle',
        overflow: 'linebreak'
      },
      headStyles: {
        fillColor: [0, 57, 107],
        textColor: [255, 255, 255],
        fontStyle: 'bold',
        fontSize: 6
      },
      // columnStyles: {
      //   0: { cellWidth: 52 }
      // },
      didParseCell: function (data) {
        if (data.row.section === 'head' && data.column.index === 0) {
          const length = data.row.raw.length;
          for (let i = 1; i <= length - 1; i++) {
            if (data.row.cells[i]) {
              data.row.cells[i].styles.halign = 'center';
            }
          }
        }
      },
      didDrawCell: function (data) {
        if (data.row.raw && data.column.index >= 1) {
          const { x, y, width, height } = data.cell;
          doc.setLineWidth(0.2);
          doc.setDrawColor(0, 0, 0);
          const isSingleLine = () => data.row.raw.some(cell => cell.type === "singleLine");
          if (isSingleLine()) {
            drawOverline(doc, x, y, width);
            drawUnderline(doc, x, y, width, height);
          }
          const isOverLine = () => data.row.raw.some(cell => cell.type === "overLine");
          if (isOverLine()) {
            drawOverline(doc, x, y, width);
          }
        }
      }
    });
    if (account.suppressZeros) {
      const totalPageCount = doc.internal.getNumberOfPages();
      doc.setPage(totalPageCount);
      doc.setFontSize(6);
      const pageHeight = doc.internal.pageSize.height;
      doc.text(footerText, 14, pageHeight - 10);
    }
    const dateTime=getFormattedDateTime();
    doc.save(`${header ? header + '-' + dateTime : 'report'}.pdf`);
  }

  const GetExportPDFHeader = (columnData, dataSource) => {
    let headers = [];
    let dataSourceDetails;
    // Check if there is more than one data source
    if (dataSource.length > 1) {
      dataSourceDetails = {
        primaryDataSource: dataSource[0].dataSourceName,
        primaryDataSourceFrom: dataSource[0].fromMonth - 1, // Adjusting index
        primaryDataSourceTo: dataSource[0].toMonth - 1,
        secondaryDataSource: dataSource[1].dataSourceName,
      };
    } else {
      dataSourceDetails = dataSource[0].dataSourceName; // Single data source case
    }
    // Loop through columnData and build the headers
    for (let i = 0; i < columnData.length; i++) {
      let currentDataSourceName = dataSourceDetails;
      // If multiple data sources, determine which data source to use
      if (dataSource.length > 1) {
        currentDataSourceName = getDataSourceName(dataSourceDetails, i);
      }

      const periodName = columnData[i].periodName;
      const month = periodName.length > 3 ? periodName.slice(0, 3) : periodName; // Extract month
      const year = columnData[i].periodYear;
      const finalHeader = `${currentDataSourceName} ${month} ${year}`;
      headers.push(finalHeader);
    }
    // Add an empty cell for the first column, followed by the headers
    const headerRow = ['Header', ...headers];
    const exportHeader = [headerRow];
    return exportHeader;
  };

  const getDataSourceName = (dataSourceDetails, index) => {
    return index <= dataSourceDetails.primaryDataSourceTo ? dataSourceDetails.primaryDataSource : dataSourceDetails.secondaryDataSource
  }

  const convertExportData = (items, isLeaf = false) => {
    let result = [];
    items.forEach((item) => {
      const { name, ...cols } = item.data;
      const rowData = {
        data: [name],
        isSection: (item.className === "report-section" ? true : false),
        isLeaf: isLeaf,
        isLeafTotal: (item.className === "bd-group-total-row" ? true : false),
        isTotal: (item.className === "report-total" ? true : false),
        isBold: (item.className === "report-total" ? true : false)
      };
      Object.keys(cols).forEach((colKey) => {
        const value = cols[colKey];
        const formattedValue = (typeof value === 'number')
          ? Math.abs(value).toLocaleString("en-US", { maximumFractionDigits: 0 })
          : value;
        rowData.data.push(formattedValue);
      });
      result.push(rowData);
      // If the current item is expanded and has children, recursively process them
      if (item.expanded && item.children && item.children.length > 0) {
        result = result.concat(convertExportData(item.children, true));
      }
    });
    return result;
  };

  const formatExportPDFBodyData = (items) => {
    const formattedData = items.map(row => {
      // Destructure the first element as description and the rest as period values
      const [description, ...periodValues] = row.data;
      const columnCount = periodValues.length;
      // Create the first column with the description
      const firstColumn = {
        cellWidth: columnCount === 5 ? 120 : (columnCount === 4 ? 150 : (columnCount === 3 ? 180 : (columnCount === 2 ? 210  : (columnCount === 1 ? 240: 52)))),
        content: description, // Description like 'Cash and due from banks'
        styles: {
          halign: 'left',
          cellPadding: row.isBold ? { left: 5 } : row.isLeaf ? row.isLeafTotal ? { left: 10 } : { left: 7 } : { left: 0 },
          ...((row.isBold || row.isLeafTotal) && {
            fontStyle: 'bold'
          })
        },
        type: (row.isLeafTotal ? "overLine" : row.isTotal ? "singleLine" : "")
      };

      // Create the rest of the columns with period values aligned to the right
      const periodColumns = periodValues.map(value => ({
        content: value, // Each period value
        ...(columnCount <= 5 && {
          cellWidth: 35
        }),
        styles: {
          halign: 'right', // Right-align for numerical values
          ...((row.isBold || row.isLeafTotal) && {
            fontStyle: 'bold'
          })

        },
        type: (row.isLeafTotal ? "overLine" : row.isTotal ? "singleLine" : "")
      }));

      // Return an array combining the description column and period columns
      return [firstColumn, ...periodColumns];
    });
    return formattedData;
  }

  function drawOverline(doc, x, y, width) {
    const overlineY = y + 1;
    doc.line(x, overlineY, x + width, overlineY);
  }
  function drawUnderline(doc, x, y, width, height) {
    const underlineY = y + height - 2;
    doc.line(x, underlineY, x + width, underlineY);
  }

  const getFormattedDateTime = () => {
    const date = new Date();
  
    // Get individual components of the date
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based, so +1
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
  
    // Construct the final formatted string
    return `${year}${month}${day}T${hours}${minutes}${seconds}`;
  };

  useEffect(() => {
    const fetchAccountData = async () => {
      try {
        const result = await axios.get(`${API_ROOT.glServiceEndpoint}/api/report/accounts?groupId=${groupId}${accountParams}`, apiOptions);
        const accountData = result.data;
        accountData.isGroupView = false;
        accountData.accountHeader = accountData.lineItems[0].lineItemData;
        accountData.accountHeader.periodSourceHeader = periodSourceHeader;
        accountData.suppressZeros = suppressZeros;
        accountData.addFooter = addFooter;


        setAccount(accountData);
        let colsData = ReportUtils.buildColumns(accountData.accountHeader.periodValues, periodMetadata);

        setColumns(colsData);
        let nodesData = ReportUtils.loadNodes(accountData.lineItems, colsData, false, false);
        setNodes(nodesData);
        setAccountLoading(false);
      } catch (error) {
        console.error(error);
      }
    };
    fetchAccountData();
  }, [accountParams]);
  if (account && columns && columns.length > 0 && nodes && nodes.length > 0) {
    return (
      <>
        <div className="report-container">
          <div className="report-title">
            <h1 className="app-title-h1">{account.title}</h1>
            <span className="report-custom-subtitle">{account.subtitle}</span>
          </div>
          <div className="text-right col-sm-8">
            <Button className="pdfBtnAccount" variant="outline-light" size="sm" onClick={generatePDF}>
              <i class="fa-regular fa-file-pdf pdfIcon"></i>
            </Button>
            <UnifyButton onClick={goBack} type="button" variant="primary">
              <span>
                <i className="fas fa-arrow-circle-left"></i> Back
              </span>
            </UnifyButton>
          </div>
        </div>
        <div className="card">
          {/* <div className="period-source-header">
            <span>{account.accountHeader.periodSourceHeader}</span>
          </div> */}
          <TreeTable
            value={nodes}
            lazy
            scrollable
            scrollHeight="65vh"
            loading={loading}
            onExpand={onExpand}
            onCollapse={onCollapse}
            footer={account.suppressZeros && account.addFooter ? ReportUtils.footer : null}
            frozenWidth="320px"
            tableClassName="tableStyle"
          >
            {columns.map((col, i) => (
              <Column
                key={col.field}
                field={col.field}
                header={col.header}
                frozen={col.frozen}
                expander={col.expander}
                align={col.align}
                body={col.body}
                className="bd-table-cell"
                style={{ textAlign: col.align, width: col.width }}
              />
            ))}
          </TreeTable>
        </div>
      </>
    );
  } else {
    return <LoadingSpinner isActive={accountLoading} />;
  }
}

export default ReportAccounts;
