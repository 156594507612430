import React, { useEffect, useState, Suspense,useMemo,useContext } from "react";
import {
  Container,
  GlobalHeader,
  Branding,
  UnifyFonts,
  UnifyReboot,
  UnifyStyles,
  GlobalHeaderProvider,
  BrandingUtilityNav,
  useUnifyContext,
} from "unify-react";
import { ThemeProvider } from "styled-components";
import { UnifyProvider } from "unify-react/lib/UnifyContext";
import { UnifyLeftNavigation } from "@deluxe/unify-left-navigation";
import GlobalStyle from "../Themes/GlobalStyle";
import { useTheme } from "../Themes/useTheme";
import { BD_MENU, PARENT_MENU_ITEMS } from "./bdMenu";
import JwtDecode from "jwt-decode";
import { getUserFdic, isPartner } from "../Utilities/userUtils";
import ErrorPage from "../Components/ErrorPage/404ErrorPage";
import axios from "axios";
import { errorHandler } from "../errorHandler";
import Error from "../Components/ErrorPage/error";
import { Outlet, NavLink } from "react-router-dom";
import {
  hasPermission,
  hasAccessPrivileges,
  hasAdminPrivileges,
  PERMISSIONS,
  ACCESS_PRIVILEGES,
  ADMINSTRATIVE_PRIVILEGES,
} from "../permissions";
import { TokenContext } from "../../Token/tokenContext";
// import TabManager from "./TabManager";
const TabManager = React.lazy(() => import('./TabManager'));

function RootLayout(props) {
  const { token, fdic, bankId, username } = useContext(TokenContext); // Access token, fdic, bankId, username from TokenContext
  const [wrapperClass, setWrapperClass] = useState("");
  const [appVisisbility, setAppVisibility] = useState("");
  const [errorCode, setErrorCode] = useState(null);
  errorHandler(axios, setErrorCode,fdic);
  const bankersSession = JSON.parse(sessionStorage.getItem("bankersSession"));
  // window.token = bankersSession.token;
  // window.bankId = bankersSession.contextEntity;
  // const decodedToken = JwtDecode(window.token);
  // window.username = decodedToken.unique_name;
  // window.fdic = getUserFdic(window.username);
  const apiOptions = { headers: { Authorization: `Bearer ${token}` } };
  const { theme, themeLoaded, getFonts } = useTheme();
  const [selectedTheme, setSelectedTheme] = useState(theme);


  useEffect(() => {
    const loadTheme = async () => {
      setSelectedTheme(theme);
    };
    loadTheme();
  }, [themeLoaded]);

  const showApp = () => {
    setAppVisibility("");
  };

  const checkPermission = () => {
    let authorizedMenuItems = BD_MENU.filter(nav => {
      switch (nav.title) {
        case PARENT_MENU_ITEMS.projection:
          return props.user && !(hasAdminPrivileges(props.user, ADMINSTRATIVE_PRIVILEGES.ProjectionAdmin,bankId)) ? null : nav;
        case PARENT_MENU_ITEMS.gl:
          return props.user && !((hasAccessPrivileges(props.user, ACCESS_PRIVILEGES.GeneralLedger,bankId)) && isPartner) ? null : nav;
        case PARENT_MENU_ITEMS.admin:
          for (let i = 0; i < nav.submenu.length; i++) {
            if (props.user && (nav.submenu[i].title === "Users") && !(hasPermission(props.user, PERMISSIONS.UserAdmin))) {
              nav.submenu.splice(i, 1);
            }
          }
          return nav;
        default:
          return nav;
      }
    })
    return authorizedMenuItems;
  };

  const memoizedMenu = useMemo(() => checkPermission(props.user), [props.user]);

  const GlobalHeaderProviderWrapper = () => {
    const { state, dispatch } = useUnifyContext();
    return (
      <GlobalHeaderProvider>
        <GlobalHeader mobileBreakpoint="md">
          <BrandingUtilityNav>
            {theme.custom.backgroundImagePath ? (
              <img id="logoImg" />
            ) : (

                <Branding
                  productName="Banker's Dashboard"
                  logoUrl="./"
                  logoType="light"
                // logoSize="lg"
                ></Branding>
              )}
            <div className="login-links">
              <NavLink to="/">{username}</NavLink>
              <span className="divider"></span>
              <NavLink to={window.location.origin}>Log Out</NavLink>
            </div>
          </BrandingUtilityNav>
        </GlobalHeader>
      </GlobalHeaderProvider>
    );
  };

  if (token && themeLoaded) {
    return (
      <>
        <UnifyFonts />
        <UnifyReboot />
        <ThemeProvider theme={theme}>
          <UnifyStyles />
          <UnifyProvider>
            <GlobalStyle />
            <div className={"App " + theme.name}>
              {<GlobalHeaderProviderWrapper />}
              <div className="body_content">
                <UnifyLeftNavigation
                  className="left_hand_menu"
                  // menuData={checkPermission()}
                  menuData={memoizedMenu}
                />
                <Container
                  className={`main-container ${wrapperClass} ${appVisisbility}`}
                  fluid="md"
                >
                  <>
                    {errorCode && errorCode !== 404 ? (
                      <Error
                        errorCode={errorCode}
                        setWrapperClass={setWrapperClass}
                        setErrorCode={setErrorCode}
                        setAppVisibility={setAppVisibility}
                      />
                    ) : null}
                    {errorCode && errorCode === 404 ? (
                      <ErrorPage
                        setErrorCode={setErrorCode}
                        setWrapperClass={setWrapperClass}
                      />
                    ) : (
                        <Suspense fallback={<div>Loading...</div>}>
                          <TabManager />
                        </Suspense>
                      )}
                  </>

                </Container>
              </div>
            </div>
          </UnifyProvider>
        </ThemeProvider>
      </>
    );
  } else {
    return null;
  }
}
export default RootLayout;
