import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import '@fortawesome/fontawesome-free/css/all.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'unify-core/dist/css/unify.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import UserContextProvider from './Users/userContext';
import TokenProvider from './Token/tokenContext';

global.$ = global.jQuery = require("jquery");
require("datatables.net");

// Initialize Unify object for global use
window.unify = {
  ...(window.unify || {}),
  $: global.$,
};

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <TokenProvider>
    <UserContextProvider>
      <App />
    </UserContextProvider>
  </TokenProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
