import React, { useState, useEffect,useContext } from "react";
import { useParams } from "react-router-dom";
import {
  Container,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
} from "unify-react";
import { API_ROOT } from "../ApiConfig/apiConfig";
import axios from "axios";
import classnames from "classnames";
import ProjectionFinancial from "./projectionFinancial";
import { TokenContext } from "../Token/tokenContext";
function ProjectionFinancials(props) {
  const { token, fdic, bankId, username } = useContext(TokenContext); // Access token, fdic, bankId, username from TokenContext
  const params = useParams();
  const projectionEndpoint = `${API_ROOT.projectionEndpoint}/api/projections/${params.bankId}/project/${params.projectId}`;
  const apiOptions = { headers: { Authorization: `Bearer ${token}` } };
  const [data, setData] = useState({ projectionName: "", editDisabled: false });
  const [activeTab, setActiveTab] = useState(0);
  const [financialDataTypes, setFinancialDataTypes] = useState([
    { typeId: "1", type: "balancesheet", name: "Balance Sheet" },
    { typeId: "2", type: "incomestatement", name: "Income Statement" },
    { typeId: "3", type: "assumptions", name: "Assumptions" },
    { typeId: "4", type: "projections-bs", name: "Projected BS" },
    { typeId: "5", type: "projections-is", name: "Projected IS" },
    { typeId: "6", type: "projections-bsChanges", name: "BS Changes" },
    { typeId: "7", type: "projections-isChanges", name: "IS Changes" },
    { typeId: "8", type: "projections-margin", name: "Margin Analysis" },
  ]);
  const [projectionsShown, setProjectionsShown] = useState(false);
  const fetchProjectionApi = async (resetTab) => {
    try {
      let response = await axios.get(projectionEndpoint, apiOptions);
      console.log("response-", response);
      setData({
        projectionName: response.data.description,
        editDisabled:
          response.data.username !== username && !response.data.isPublic,
        isPublic: response.data.isPublic,
      });
    } catch (error) {}
    if (resetTab) {
      setActiveTab(0);
    }
  };
  const updateProjection = () => {
    fetchProjectionApi(false);
  };
  function showProjectionTabs() {
    setProjectionsShown(true);
  }
 
function changeActiveTab(index){
  setActiveTab(index)
}
  useEffect(() => {
    fetchProjectionApi(true);
  }, []);
  return (
    <Container
      className="projection-financials-header"
      data-testid="projection-financials"
    >
      <h1 className="app-title-h1">{data.projectionName}</h1>
      <Nav tabs id="financial-tabs">
        {financialDataTypes.map(({ type, name },index) =>
          !type.match(/projections */) || projectionsShown ? (
            <NavItem key={`${type}-nav`}>
              <NavLink
                className={classnames({ active: activeTab === index })}
                id={index}
                onClick={() => setActiveTab(index)}
              >
                {name}
              </NavLink>
            </NavItem>
          ) : (
            <></>
          )
        )}
      </Nav>
      <TabContent activeTab={activeTab}>
        {financialDataTypes.map(({ type, name },index) => (
          <TabPane tabId={index} key={`${type}-pane`}>
            <Row>
              <Col sm="12">
                <ProjectionFinancial
                  name={activeTab===index? name: ""}
                  financialDataType={activeTab===index? type: ""}
                  id={params.projectId}
                  bankId={params.bankId}
                  editDisabled={data.editDisabled}
                  isPublic={data.isPublic}
                  token={token}
                  onProjectionUpdated={updateProjection}
                  showProjectionTabs={showProjectionTabs}
                  projectionsShown={projectionsShown}
                  changeActiveTab={changeActiveTab}
                />
              </Col>
            </Row>
          </TabPane>
        ))}
      </TabContent>
    </Container>
  );
}
export default ProjectionFinancials;
