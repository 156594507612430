import axios from "axios";
import React, { useState, useEffect,useContext } from "react";
import { API_ROOT } from "../../ApiConfig/apiConfig";
import "./customReport.css";
import { TreeTable } from "primereact/treetable";
import { Column } from "primereact/column";
import { LoadingSpinner } from "@deluxe/unify-loading-spinner";
import _ from "lodash";
import * as ReportUtils from "./customReportUtilities";
import ReportAccounts from './customReportAccount';
import { Button } from 'react-bootstrap';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { TokenContext } from "../../Token/tokenContext";

function CustomReport({
  headerTitle,
  reportParameters,
  suppressZeros,
  addFooter,
  setLoadingState,
}) {
  const { token, fdic, bankId, username } = useContext(TokenContext); // Access token, fdic, bankId, username from TokenContext
  const apiOptions = { headers: { Authorization: `Bearer ${token}` } };
  const [report, setReport] = useState(null);
  const [nodes, setNodes] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isDetailedReport, setIsDetailedReport] = useState(false);
  const [showReport, setShowReport] = useState(true);
  const [showAccount, setShowAccount] = useState(false);
  const [accountParams, setAccountParams] = useState(null);
  const [groupId, setGroupId] = useState(null);
  const [dataSource, setDataSource] = useState(null);
  const [reportMetaData, SetReportMetaData] = useState(null);

  let foundNode = false;
  const [expandedKeys, setExpandedKeys] = useState({});

  const [columns, setColumns] = useState(null);

  const extractFooter = (paramsWithFooter) => {
    let footerParamIndex = paramsWithFooter.lastIndexOf("&");
    return paramsWithFooter.substring(0, footerParamIndex);
  };
  const [reportParams, setReportParams] = useState(null);

  const getUpdatedNodes = (childNodes, nodeKey, updatedNode, expanded) => {
    let _childNodes = childNodes.map((childNode) => {
      if (!foundNode) {
        if (childNode.key === nodeKey) {
          childNode = updatedNode;
          childNode.expanded = expanded;
          foundNode = true;
        } else if (
          childNode.children &&
          childNode.children.length > 0 &&
          !foundNode
        ) {
          childNode.children = getUpdatedNodes(
            childNode.children,
            nodeKey,
            updatedNode,
            expanded
          );
        }
      }
      return childNode;
    });
    return _childNodes;
  };
  const onExpand = (e) => {
    if (e.node.children && e.node.children.length > 0) {
      let expandedNode = _.cloneDeep(e.node);
      let totNode = _.cloneDeep(expandedNode);
      expandedNode.data = { name: e.node.data.name };
      totNode.id = "tot" + totNode.id;
      totNode.key = "tot" + totNode.key;
      totNode.lineItemCategory = 2;
      totNode.expanded = true;
      totNode.className = "bd-group-total-row";
      delete totNode.children;
      expandedNode.children.push(totNode);
      let _nodes = nodes.map((node) => {
        if (!foundNode) {
          if (node.key === e.node.key) {
            node = expandedNode;
            node.expanded = true;
            foundNode = true;
          } else if (node.children && node.children.length > 0 && !foundNode) {
            node.children = getUpdatedNodes(
              node.children,
              e.node.key,
              expandedNode,
              true
            );
          }
        }
        return node;
      });
      foundNode = false;
      let _expandedKeys = { ...expandedKeys };
      _expandedKeys[e.node.key] = true;
      setExpandedKeys(_expandedKeys);
      setNodes(_nodes);
    }
  };

  const onCollapse = (e) => {
    if (e.node.children && e.node.children.length > 0) {
      let collapsedNode = _.cloneDeep(e.node);
      let nodeData = collapsedNode.children.pop();
      delete nodeData.className;
      collapsedNode.data = nodeData.data;
      let _nodes = nodes.map((node) => {
        if (!foundNode) {
          if (node.key === e.node.key) {
            node = collapsedNode;
            node.expanded = false;
            foundNode = true;
          } else if (node.children && node.children.length > 0 && !foundNode) {
            node.children = getUpdatedNodes(
              node.children,
              e.node.key,
              collapsedNode,
              false
            );
          }
        }
        return node;
      });
      foundNode = false;
      let _expandedKeys = { ...expandedKeys };
      if (_expandedKeys[e.node.key]) delete _expandedKeys[e.node.key];
      setExpandedKeys(_expandedKeys);
      setNodes(_nodes);
    }
  };

  const getAccountQuery = (reportParams) => {
    return reportParams.includes("&summaryOnly=true") ? reportParams.replace("&summaryOnly=true", "") : reportParams.replace("&summaryOnly=false", "");
  };

  const rowClicked = (e) => {
    if (e.node.isClickable) {
      setShowReport(false);
      setShowAccount(true);
      setAccountParams(reportParams ? getAccountQuery(reportParams) : '');
      setGroupId(e.node.lineItemNumber);
    } else {
      return false;
    }
  };

  const expandAll = (reportNodes) => {
    let _expandedKeys = {};
    for (let node of reportNodes) {
      expandNode(node, _expandedKeys);
    }
    setExpandedKeys(_expandedKeys);
  };

  const expandNode = (node, _expandedKeys) => {
    if (node.children && node.children.length) {
      _expandedKeys[node.key] = true;
      for (let child of node.children) {
        expandNode(child, _expandedKeys);
      }
    }
  };

  const setReportProperties = () => {
    setReportParams(extractFooter(reportParameters));
    setIsDetailedReport(reportParameters.includes("summaryOnly=false"));
  };

  useEffect(() => {
    setShowReport(true);
    setReportProperties();
    const fetchReportData = async () => {
      try {
        let params = extractFooter(reportParameters);
        const result = await axios.get(
          `${API_ROOT.glServiceEndpoint}/api/report?${params}`,
          apiOptions
        );
        let reportData = result.data;
        const metadata = reportData.headerMetadata.periodMetadata;
        reportData.isGroupView = true;
        reportData.reportHeader = reportData.lineItems[0].lineItemData;
        setDataSource(metadata[0].dataSourceName);
        reportData.reportHeader.periodSourceHeader = `${metadata[0].periodTypeName} - ${metadata[0].dataSourceName}`;
        reportData.suppressZeros = suppressZeros;
        reportData.addFooter = addFooter;
        SetReportMetaData(metadata);
        setReport(reportData);
        let colsData = ReportUtils.buildColumns(
          reportData.reportHeader.periodValues,
          metadata
        );
        setColumns(colsData);
        let nodesData = ReportUtils.loadNodes(
          reportData.lineItems,
          colsData,
          true,
          params.includes("summaryOnly=false")
        );
        if (params.includes("summaryOnly=false")) {
          expandAll(nodesData);
        }
        setNodes(nodesData);
        setLoading(false);
        setLoadingState(false);
      } catch (error) {
        console.error(error);
      }
    };

    fetchReportData();
    return () => {
      setReport(null);
      setExpandedKeys({});
    };
  }, [reportParameters]);

  function drawOverline(doc, x, y, width) {
    const overlineY = y + 1;
    doc.line(x, overlineY, x + width, overlineY);
  }

  function drawUnderline(doc, x, y, width, height) {
    const underlineY = y + height - 2;
    doc.line(x, underlineY, x + width, underlineY);
  }

  function drawDoubleUnderline(doc, x, y, width, height) {
    const underlineY = y + height - 2;
    doc.line(x, underlineY, x + width, underlineY);
    doc.line(x, underlineY + 1, x + width, underlineY + 1);
  }

  const generatePDF = () => {
    const liabilitySection = ["Liabilities"];
    const equitySection = ["Equity"];
    const interestExpenseSection = ["Interest Expense"];
    const nonInterestIncomeSection = ["Non Interest Income"];
    const nonInterestExpenseSection = ["Non Interest Expense"];
    let isLiabilitiesSection = false;
    let isEquitySection = false;
    let isInterestExpenseSection = false;
    let isNonInterestIncomeSection = false;
    let isNonInterestExpenseSection = false;

    const exportHeader = GetExportPDFHeader(report.reportHeader.periodValues, reportMetaData);
    const exportData = convertExportData(nodes);
    const exportBody = formatExportPDFBodyData(exportData.slice(1));
    // const bodyData = exportBody.slice(1); // Remove the "Assest section to show"
    let sectionHeader = (report.title.toLowerCase() === "balance sheet" ? "Assets" : "Interest Income");
    const footerText = "Rows with zero balances are suppressed";

    const doc = new jsPDF('landscape');
    doc.setFontSize(14);
    doc.text(report.title.toString(), 14, 15);
    doc.setFontSize(12);
    doc.text(report.subtitle.toString(), 14, 22);
    doc.autoTable({
      startY: 29,
      head: [[sectionHeader, ...exportHeader[0].slice(1)]],
      body: exportBody,
      styles: {
        fontSize: 6,
        valign: 'middle',
        overflow: 'linebreak'
      },
      headStyles: {
        fillColor: [0, 57, 107],
        textColor: [255, 255, 255],
        fontStyle: 'bold',
        fontSize: 6
      },
      // columnStyles: {
      //   0: { cellWidth: 52 }
      // },
      willDrawCell: (data) => {
        const isSection = (headerArray) =>
          headerArray.some(header =>
            data.row.raw.some(cell => cell.content && cell.content === header)
          );
        const pageHeight = doc.internal.pageSize.height;
        const currentY = data.cursor.y;
        const rowHeight = 12;
        const remainingSpace = pageHeight - currentY;

        if (report.title.toLowerCase() === "balance sheet") {
          if (isSection(liabilitySection)) {
            isLiabilitiesSection = true;
          }
          else if (isSection(equitySection)) {
            isEquitySection = true;
            isLiabilitiesSection = false;
          }

          if (data.row.section === 'head' && data.column.index === 0) {
            if (isLiabilitiesSection) {
              if (data.row.raw[0] === "Assets") {
                data.row.raw[0] = "Liabilities";
                data.row.cells[0].text = ['Liabilities'];
                data.row.cells[0].raw = "Liabilities";
              }
            }
            else if (isEquitySection) {
              if (data.row.raw[0] === "Assets" || data.row.raw[0] === "Liabilities") {
                data.row.raw[0] = "Equity";
                data.row.cells[0].text = ['Equity'];
                data.row.cells[0].raw = "Equity";
              }
            }
          }
          if (data.cell && data.cell.raw && data.cell.raw.type === 'isSection') {
            if (remainingSpace < rowHeight * 2) {
              return false;
            }
          }
        }
        else if (report.title.toLowerCase() === "income statement") {
          if (isSection(interestExpenseSection)) {
            isInterestExpenseSection = true;
          }
          else if (isSection(nonInterestIncomeSection)) {
            isInterestExpenseSection = false;
            isNonInterestIncomeSection = true;
          }
          else if (isSection(nonInterestExpenseSection)) {
            isInterestExpenseSection = false;
            isNonInterestIncomeSection = false;
            isNonInterestExpenseSection = true;
          }
          if (data.row.section === 'head' && data.column.index === 0) {
            if (isInterestExpenseSection) {
              if (data.row.raw[0] === "Interest Income") {
                data.row.raw[0] = "Interest Expense";
                data.row.cells[0].text = ['Interest Expense'];
                data.row.cells[0].raw = "Interest Expense";
              }
            }
            else if (isNonInterestIncomeSection) {
              if (data.row.raw[0] === "Interest Income" || data.row.raw[0] === "Interest Expense") {
                data.row.raw[0] = "Non Interest Income";
                data.row.cells[0].text = ['Non Interest Income'];
                data.row.cells[0].raw = "Non Interest Income";
              }
            }
            else if (isNonInterestExpenseSection) {
              if (data.row.raw[0] === "Interest Income" || data.row.raw[0] === "Interest Expense" ||
                data.row.raw[0] === "Non Interest Income") {
                data.row.raw[0] = "Non Interest Expense";
                data.row.cells[0].text = ['Non Interest Expense'];
                data.row.cells[0].raw = "Non Interest Expense";
              }
            }

          }
          if (data.cell && data.cell.raw && data.cell.raw.type === 'isSection') {
            if (remainingSpace < rowHeight * 2) {
              return false;
            }
          }
        }
      },
      didParseCell: function (data) {
        if (data.row.section === 'head' && data.column.index === 0) {
          const length = data.row.raw.length;
          for (let i = 1; i <= length - 1; i++) {
            if (data.row.cells[i]) { // Check if the cell exists
              data.row.cells[i].styles.halign = 'center';
            }
          }
        }
      },
      didDrawCell: function (data) {
        const headerGroups = {
          balanceSheet: {
            doubleLine: ["Total Assets", "Total Liabilities & Equity"],
            singleLine: ["Total Liabilities"],
            overLine: ["Total Deposits", "Total Equity"]
          },
          incomeStatement: {
            doubleLine: ["Net Income"],
            singleLine: [
              "Total Interest Income",
              "Net In. Inc. After Prov. for Credit Losses",
              "Total Non Interest Income"
            ],
            overLine: [
              "Total Interest Expense",
              "Net Interest Income",
              "Total Non Interest Expense",
              "Income Before Taxes"
            ]
          }
        };
        if (data.row.raw && data.column.index >= 1) {
          const { x, y, width, height } = data.cell;
          doc.setLineWidth(0.2);
          doc.setDrawColor(0, 0, 0);

          const isBalanceSheet = report.title.toLowerCase() === "balance sheet";
          const isIncomeStatement = report.title.toLowerCase() === "income statement";

          let relevantHeaders = {};

          // Assign the correct header group based on the report type
          if (isBalanceSheet) {
            relevantHeaders = headerGroups.balanceSheet;
          } else if (isIncomeStatement) {
            relevantHeaders = headerGroups.incomeStatement;
          }

          // Generic function to check if a row contains any header from a specific group
          const containsHeader = (headerArray) =>
            headerArray.some(header =>
              data.row.raw.some(cell => cell.content && cell.content.includes(header))
            );

          // Check for double line headers
          if (containsHeader(relevantHeaders.doubleLine)) {
            drawOverline(doc, x, y, width);
            drawDoubleUnderline(doc, x, y, width, height);
          }

          // Check for single line headers
          if (containsHeader(relevantHeaders.singleLine)) {
            drawOverline(doc, x, y, width);
            drawUnderline(doc, x, y, width, height);
          }
          // Check for overline headers
          if (containsHeader(relevantHeaders.overLine)) {
            drawOverline(doc, x, y, width);
          }

          const containsChildTotal = () => data.row.raw.some(cell => cell.type === "isChildTotal");
          // Apply the overline only when type is "isChildTotal"
          if (containsChildTotal()) {
            drawOverline(doc, x, y, width);
          }
        }
      },
    });

    if (report.suppressZeros) {
      const totalPageCount = doc.internal.getNumberOfPages();
      doc.setPage(totalPageCount);
      doc.setFontSize(6);
      const pageHeight = doc.internal.pageSize.height;
      doc.text(footerText, 14, pageHeight - 10);
    }
    const dateTime = getFormattedDateTime();
    doc.save(`${headerTitle ? headerTitle + '-' + dateTime : 'report'}.pdf`);
  }

  const GetExportPDFHeader = (columnData, dataSource) => {
    let headers = [];
    let dataSourceDetails;

    // Check if there is more than one data source
    if (dataSource.length > 1) {
      dataSourceDetails = {
        primaryDataSource: dataSource[0].dataSourceName,
        primaryDataSourceFrom: dataSource[0].fromMonth - 1, // Adjusting index
        primaryDataSourceTo: dataSource[0].toMonth - 1,
        secondaryDataSource: dataSource[1].dataSourceName,
      };
    } else {
      dataSourceDetails = dataSource[0].dataSourceName; // Single data source case
    }

    // Loop through columnData and build the headers
    for (let i = 0; i < columnData.length; i++) {
      let currentDataSourceName = dataSourceDetails;

      // If multiple data sources, determine which data source to use
      if (dataSource.length > 1) {
        currentDataSourceName = getDataSourceName(dataSourceDetails, i);
      }

      const periodName = columnData[i].periodName;
      const month = periodName.length > 3 ? periodName.slice(0, 3) : periodName; // Extract month
      const year = columnData[i].periodYear;

      const finalHeader = `${currentDataSourceName} ${month} ${year}`;
      headers.push(finalHeader);
    }

    // Add an empty cell for the first column, followed by the headers
    const headerRow = ['Header', ...headers];
    const exportHeader = [headerRow];
    //const exportHeader = [headers];

    return exportHeader;
  };

  const getDataSourceName = (dataSourceDetails, index) => {
    return index <= dataSourceDetails.primaryDataSourceTo ? dataSourceDetails.primaryDataSource : dataSourceDetails.secondaryDataSource
  }

  const formatExportPDFBodyData = (items) => {

    const formattedData = items.map(row => {
      // Destructure the first element as description and the rest as period values
      const [description, ...periodValues] = row.data;
      const columnCount = periodValues.length;

      // Create the first column with the description
      const firstColumn = {
        content: description, // Description like 'Cash and due from banks'
        styles: {
          cellWidth: columnCount === 5 ? 120 : (columnCount === 4 ? 150 : (columnCount === 3 ? 180 : (columnCount === 2 ? 210  : (columnCount === 1 ? 240: 52)))),
          halign: 'left', // Left-align the description
          cellPadding: row.isChild ? row.isChildTotal ? { left: 10 } : { left: 5 } : row.isNestedChild ? row.isChildTotal ? { left: 15 } : { left: 10 } : { left: 0 }, // Indentation for child rows
          // Conditionally add styles if isSection is true
          ...(row.isSection && {
            fillColor: [0, 57, 107],
            textColor: [255, 255, 255],
            fontSize: 6,
            minCellHeight: 1,
            fontStyle: 'bold',
            cellPadding: { top: 1.7, bottom: 1.7, left: 1 }
          }),
          ...((row.isChildTotal || row.isBold) && {
            fontStyle: 'bold'
          })
        },
        // Conditionally add colSpan if isSection is true
        ...(row.isSection && { colSpan: 14 }),
        type: (row.isChildTotal ? "isChildTotal" : row.isSection ? "isSection" : "")
      };

      // Create the rest of the columns with period values aligned to the right
      const periodColumns = periodValues.map(value => ({
        content: value, // Each period value
        ...(columnCount <= 5 && {
          cellWidth: 35
        }),
        styles: {
          halign: 'right', // Right-align for numerical values
          ...((row.isChildTotal || row.isBold) && {
            fontStyle: 'bold'
          })
        },
        type: (row.isChildTotal ? "isChildTotal" : "")
      }));

      // Return an array combining the description column and period columns
      return [firstColumn, ...periodColumns];
    });
    return formattedData;
  }



  const convertExportData = (items, isParent = true, isNestedChild = false) => {
    let result = [];
    items.forEach((item) => {
      const { name, ...cols } = item.data;

      // Determine if the current item is a nested child
      const rowData = {
        data: [name], // Store row data in an array inside an object
        isSection: (item.className === "report-section" ? true : false),
        isParent: isParent && !isNestedChild, // If it's top-level and not nested, it's a parent
        isChild: !isParent && !isNestedChild, // If it's a direct child and not a nested child
        isNestedChild: isNestedChild, // This flag is true only for nested children
        isChildTotal: (item.className === "bd-group-total-row" ? true : false),
        isBold: (item.className === "report-grandtotal" || item.className === "report-total" ? true : false)
      };

      // Process each column value
      Object.keys(cols).forEach((colKey) => {
        const value = cols[colKey];
        const formattedValue = (typeof value === 'number')
          ? Math.abs(value).toLocaleString("en-US", { maximumFractionDigits: 0 })
          : value;
        rowData.data.push(formattedValue); // Push data into the 'data' array
      });

      result.push(rowData); // Add row object with isParent, isChild, and isNestedChild flags to result

      // If the current item is expanded and has children, recursively process them
      if (item.expanded && item.children && item.children.length > 0) {
        const isCurrentChildNested = !isParent; // If this item is not a parent, its children are nested
        result = result.concat(convertExportData(item.children, false, isCurrentChildNested));
      }
    });

    return result;
  };
  const getFormattedDateTime = () => {
    const date = new Date();

    // Get individual components of the date
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based, so +1
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    // Construct the final formatted string
    return `${year}${month}${day}T${hours}${minutes}${seconds}`;
  };

  if (report && columns && columns.length > 0 && nodes && nodes.length > 0 && showReport) {
    return (
      <>
        <div className="report-title-container">
          <div className="row">
            <div className="text-left col-sm-4">
              <h1 data-testid="report-custom-title" className="app-title-h1">{report.title}</h1>
            </div>
            <div className="text-right col-sm-8">

              <Button className="pdfBtn" variant="outline-light" size="sm" onClick={generatePDF}>
                <i class="fa-regular fa-file-pdf pdfIcon"></i>
              </Button>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <span data-testid="report-custom-subtitle" className="report-custom-subtitle">{report.subtitle}</span>
            </div>
          </div>


        </div>
        <div className="card">
          <div
            data-testid="report-custom-period-source"
            className="period-source-header"
          >
          </div>
          <TreeTable
            value={nodes}
            scrollable
            scrollHeight="65vh"
            expandedKeys={expandedKeys}
            onExpand={onExpand}
            onCollapse={onCollapse}
            footer={
              report.suppressZeros && report.addFooter
                ? ReportUtils.footer
                : null
            }
            frozenWidth="288px"
            onRowClick={rowClicked}
            tableClassName="tableStyle"
          >
            {columns.map((col, i) => (
              <Column
                key={col.field}
                field={col.field}
                header={col.header}
                frozen={col.frozen}
                expander={col.expander}
                align={col.align}
                body={col.body}
                className="bd-table-cell"
                style={{ textAlign: col.align, width: col.width }}
              />
            ))}
          </TreeTable>
        </div>
        <div></div>
      </>
    );
  }
  else if (report && !showReport && showAccount && accountParams) {
    return <ReportAccounts
      accountParams={accountParams}
      groupId={groupId}
      periodSourceHeader={report.reportHeader.periodSourceHeader}
      suppressZeros={report.suppressZeros}
      addFooter={report.addFooter}
      periodMetadata={report.headerMetadata.periodMetadata}
      setShowReport={setShowReport}
      setShowAccount={setShowAccount}
      header={headerTitle}
    />

  } else {
    return <LoadingSpinner isActive={loading} />;
  }
}

export default CustomReport;
