import React, { createContext, useState, useEffect, useMemo, useRef } from "react";
import JwtDecode from "jwt-decode";
import { getUserFdic } from "../Shared/Utilities/userUtils";
import { API_ROOT } from "../ApiConfig/apiConfig";

export const TokenContext = createContext({
  token: null,
  bankId: null,
  username: null,
  fdic: null,
});

const decodeTokenData = (token) => {
  try {
    const decodedToken = JwtDecode(token);
    return {
      username: decodedToken.unique_name,
      fdic: getUserFdic(decodedToken.unique_name),
    };
  } catch (error) {
    console.error("Error decoding token:", error);
    return null;
  }
};

const TokenProvider = ({ children }) => {
  const [token, setToken] = useState(null);
  const [bankId, setBankId] = useState(null);
  const [username, setUsername] = useState(null);
  const [fdic, setFdic] = useState(null);

  const sessionRef = useRef(JSON.parse(sessionStorage.getItem("bankersSession")));

  useEffect(() => {
    const handleBankersSession = (event) => {
      if (!sessionRef.current && event.origin === API_ROOT.clientEndpoint) {
        sessionRef.current = event.data;
        sessionStorage.setItem("bankersSession", JSON.stringify(event.data));
        initializeSession(event.data);
      }
    };

    if (!sessionRef.current && window.opener) {
      window.onload = () => {
        window.opener.postMessage("loaded", API_ROOT.clientEndpoint);
      };
    }

    window.addEventListener("message", handleBankersSession);
    return () => {
      window.removeEventListener("message", handleBankersSession);
    };
  }, []);

  const initializeSession = (session) => {
    if (session) {
      const { token: sessionToken, contextEntity } = session;
      const decodedData = decodeTokenData(sessionToken);

      if (decodedData) {
        setToken(sessionToken);
        setBankId(contextEntity);
        setUsername(decodedData.username);
        setFdic(decodedData.fdic);
      }
    }
  };

  useEffect(() => {
    if (sessionRef.current) {
      initializeSession(sessionRef.current);
    }
  }, []); // Initialize on mount if session exists

  const contextValue = useMemo(
    () => ({
      token,
      bankId,
      username,
      fdic,
    }),
    [token, bankId, username, fdic]
  );

  return (
    <TokenContext.Provider value={contextValue}>
      {children}
    </TokenContext.Provider>
  );
};

export default TokenProvider;
