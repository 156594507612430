import React, { useState, useEffect,useContext } from "react";
import { API_ROOT } from "../ApiConfig/apiConfig";
import _ from "lodash";
import axios from "axios";
import { LoadingSpinner } from "@deluxe/unify-loading-spinner";
import { TokenContext } from "../Token/tokenContext";
export const UserContext = React.createContext({
  user: null,
});
const UserContextProvider = (props) => {
  const { token, fdic } = useContext(TokenContext); // Access token, fdic, bankId, username from TokenContext
  const apiOptions = { headers: { Authorization: `Bearer ${token}` } };
  const [userProfile, setUserProfile] = useState(null);

  const getEntitiesList = async () => {
    let entitiesList = await axios.get(
      `${API_ROOT.fdicEndpoint}/api/Fdic/${fdic}/entities-list`,
      apiOptions
    );
    return entitiesList.data;
  };
  const mapUser = (user, entitiesList) => {
    let userCopy = _.cloneDeep(user);
    userCopy = setupUserEntities(userCopy, entitiesList);
    return userCopy;
  };
  const setupUserEntities = (userCopy, entitiesList) => {
    userCopy.entities.forEach((entity) => {
      let entResult = entitiesList.find(
        (entResult) => entResult.id === entity.bankId
      );
      entity.name = entResult.name;
      entity.listOrder = entResult.listOrder;
    });
    userCopy.entities = userCopy.entities.sort(
      (a, b) => a.listOrder - b.listOrder
    );
    return userCopy;
  };
  const getUserProfile = async () => {
    let result = await axios.get(
      `${API_ROOT.userEndpoint}/api/${fdic}/users/profile`,
      apiOptions
    );
    let entities = fdic && (await getEntitiesList());
    setUserProfile(entities ? mapUser(result.data, entities) : result.data);
  };

  useEffect(() => {
    if (token && !userProfile) {
      getUserProfile();
    }
  },[token]);

  if (userProfile) {
    return (
      <>
        <UserContext.Provider value={{ user: userProfile }}>
          {props.children}
        </UserContext.Provider>
      </>
    );
  } else {
    return <LoadingSpinner isActive={!userProfile} />;
  }
};
export default UserContextProvider;
