import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Row, PanelFooter, Col, Container, Button, Table, Checkbox, Form, FormGroup, Input, Label} from 'unify-react';
import { LoadingSpinner } from '@deluxe/unify-loading-spinner';
import { API_ROOT } from '../ApiConfig/apiConfig';
import PeerGroupCard from './peerGroupCard';
import styles from '../Users/users.module.css';
import peerMockData from '..//mock_data/peergroups';
import axios from 'axios';
import PeerGroupSearchModal from './peerGroupSearchModal';
import './peerGroup.css';
import PeerGroupSearchTable from './peerGroupSearchTable';
import { Link } from 'react-router-dom';
import { TokenContext } from "../Token/tokenContext";

let confirmedPeerGroupsMap = {};
function PeerGroup (props) {
    const { token, fdic } = useContext(TokenContext); // Access token, fdic, bankId, username from TokenContext

    const navigate = useNavigate();
    const location = useLocation();
    const [data, setData] = useState({isLoading: true, peerGroups: [], count: 0});
    const apiOptions = { headers: { 'Authorization': `Bearer ${token}` } };
    let isEditingPeerGroup = location.state && location.state.isEditingPeerGroup;
    const [showModal, setShowModal] = useState(!isEditingPeerGroup);
    const [searchSelections, setSearchSelections] = useState([]);
    const [confirmedPeerGroups, setConfirmedPeerGroups] = useState([]);
    const [selections, setSelections] = useState([]);
    const [updateTable, setUpdateTable] = useState(0);
    const [dataTable, setDataTable] = useState(null);
    const [updated, setUpdated] = useState(0);
    const fetchPeerGroups = async () => {
        // const result = await axios.get(
        //     `${API_ROOT.userEndpoint}/api/${fdic}/users`, apiOptions
        // );
        setData({isLoading: false, peerGroups: peerMockData.peer_groups, count: peerMockData.peer_groups.length});
    };

    const onSave = () => {
        let alertText = isEditingPeerGroup ? 'Group successfully edited' : 'New group successfully added'
        navigate(`/peergroups`, {state: {alertText: alertText}});
    }

    const openModal = () => { setShowModal(true); }
    const closeModal = () => { setShowModal(false); }

    const removeSelections = () => {
        setConfirmedPeerGroups(confirmedPeerGroups.filter(function(confirmedSelection) {
            return selections.indexOf(confirmedSelection) === -1;
        }))
        setSelections([]);
    }

    const redrawTable = () => {
        dataTable.rows().invalidate('data').draw(false);
    }

    const saveSelections = async () => {
        searchSelections.forEach(function(peerGroup){
            if(confirmedPeerGroups[peerGroup.id] != -1){
                confirmedPeerGroupsMap[peerGroup.id] = peerGroup;
            }
        });
        setUpdated(updated + 1)
        let merged = confirmedPeerGroups.concat(searchSelections)
        setConfirmedPeerGroups(merged)
    }

    useEffect(() => {
        fetchPeerGroups();
    }, []);
    return (
        <>
            {data.isLoading ? (<LoadingSpinner isActive={data.isLoading} />) : (
                <>
                <Container className={styles.fixed_container + ' peergroup-edit'}>
                    {showModal &&
                        <PeerGroupSearchModal confirmedPeerGroups={confirmedPeerGroups} saveSelections={saveSelections} selections={searchSelections} setSelections={setSearchSelections} formType="Create" handleClose={closeModal} show={showModal} />
                    }
                    <div>
                        <Container>
                            <Row className="no-gutters table-header" xs="1" sm="2">
                                <Col>
                                    <h1 className="margin-bottom-0">Manage Peer Groups</h1>
                                </Col>
                            </Row>
                            <Row className={`no-gutters margin-top-50`}>
                                <Col xs="4" className="peergroup-left-panel">
                                    <Row className="no-gutters margin-bottom-7">
                                        <h6 className="mt-auto peergroup-header">
                                            Group Details
                                        </h6>
                                    </Row>
                                    <Row className="no-gutters unify panel">
                                        <div className="mt-auto panel-body">
                                          <FormGroup>
                                            <Label for="groupName">Group Name</Label>
                                            <Input
                                              type="input"
                                              name="groupName"
                                              onChange={() => setUpdated(updated + 1)}
                                            />
                                          </FormGroup>
                                          <FormGroup>
                                            <Label for="groupDescription">Group Description</Label>
                                            <Input
                                              type="textarea"
                                              id="group-description"
                                              name="groupDescription"
                                              onChange={() => setUpdated(updated + 1)}
                                            />
                                          </FormGroup>
                                        </div>
                                    </Row>
                                </Col>
                                <Col xs="8" className="peergroup-right-panel">
                                    <Row className="no-gutters justify-content-end margin-bottom-7">
                                        <Col xs="6">
                                            <h6 className="mt-auto peergroup-header">
                                                Peer Group List
                                            </h6>
                                             <span className={`${styles.users_result} peergroup-search-results`}>Results: {confirmedPeerGroups.length}</span>
                                        </Col>
                                        <Col xs="6" className="d-flex justify-content-end">
                                            <a className={`${styles.add_user_btn} add-peergroup-button btn btn-primary`} onClick={openModal} role="button">
                                                Add Peers
                                            </a>
                                        </Col>
                                    </Row>

                                    {(!!confirmedPeerGroups.length && !showModal) ?
                                        (
                                        <div>
                                             <PeerGroupSearchTable type="saved" data={confirmedPeerGroups} setSelections={setSelections} updateTable={updateTable} setDataTable={setDataTable}></PeerGroupSearchTable>
                                        </div>)
                                        : (
                                            <Row className="mt-auto no-gutters unify panel">
                                                <div className="mt-auto peer-group-inner-container peer-group-manage-table panel-body">
                                                    <div class="add-search" onClick={openModal}>
                                                        <div>Add Peers</div>
                                                    </div>
                                                </div>
                                            </Row>
                                        )}
                                    <Row className="manage-peergroup-footer">
                                        <Col xs="6">
                                        { !!selections.length &&
                                            <Button color="danger" onClick={removeSelections}>Remove</Button>
                                        }
                                        </Col>
                                        <Col xs="6" className="right-buttons">
                                            <Link to="/peergroups" className="btn cancel btn-secondary" role="button">Cancel</Link>
                                            <Button color="primary" onClick={onSave} disabled={!updated}>Save</Button>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </Container>
                </>
            )}
        </>
    );
}

export default PeerGroup;