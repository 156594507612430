import React, { useState, useEffect,useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Row, Col, Container, Input, Alert } from 'unify-react';
import { LoadingSpinner } from '@deluxe/unify-loading-spinner';
import { API_ROOT } from '../ApiConfig/apiConfig';
import PeerGroupCard from './peerGroupCard';
import styles from '../Users/users.module.css';
import peerMockData from '..//mock_data/peergroups';
import axios from 'axios';
import ConfirmModal from '../Shared/Components/confirmModal';
import { Link } from 'react-router-dom';
import { TokenContext } from "../Token/tokenContext";

let peerGroupToDelete;
function PeerGroups (props) {
    const { token, fdic, bankId, username } = useContext(TokenContext); // Access token, fdic, bankId, username from TokenContext
    const navigate = useNavigate();
    const location = useLocation();
    const [data, setData] = useState({isLoading: true, peerGroups: [], count: 0});
   
    const apiOptions = { headers: { 'Authorization': `Bearer ${token}` } };
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertText, setAlertText] = useState("");

    const fetchPeerGroups = async () => {
        // const result = await axios.get(
        //     `${API_ROOT.userEndpoint}/api/${fdic}/users`, apiOptions
        // );
        setData({isLoading: false, peerGroups: peerMockData.peer_groups, count: peerMockData.peer_groups.length});
    };

    const openDeleteModal = (peerGroupId) => {
        peerGroupToDelete = peerGroupId
        setShowDeleteModal(true);
    }

    const addAlert = (text) => {
        setAlertText(text);
        setAlertOpen(true);
    }

    const editPeerGroup = (userId) => {
        navigate(`/peergroup/${userId}`, {state: {isEditingPeerGroup: true}});
    }

    const deletePeerGroup = async () => {
        setShowDeleteModal(false);
        setAlertText('New group successfully deleted');
        setAlertOpen(true);
        setData({peerGroups: data.peerGroups.filter(function(peerGroup) {
            return peerGroup.id !== peerGroupToDelete;
        })})
    }

    useEffect(() => {
        if(location.state && location.state.alertText){
            addAlert(location.state.alertText)
        }
        fetchPeerGroups();
    }, []);

    return (
        <>
            {data.isLoading ? (<LoadingSpinner isActive={data.isLoading} />) : (
                <>
                <Container className={styles.fixed_container}>
                    <ConfirmModal title="Delete Peer Group" body="Are you sure you want to delete this peer group?" buttonLabel="Yes" closeLabel="Cancel"
                        show={showDeleteModal} handleClose={() => setShowDeleteModal(false)} handleConfirm={() => deletePeerGroup()}/>
                    <div className={styles.fixed_header}>
                        <Container>
                            <Row className="no-gutters table-header" xs="1" sm="2">
                                <Col sm="6">
                                    <h1 className="margin-bottom-0">Manage Peer Groups</h1>
                                </Col>
                                <Col sm="6">
                                    <Alert color='success' isOpen={alertOpen} toggle={() => setAlertOpen(!alertOpen)}>
                                        {alertText}
                                    </Alert>
                                </Col>
                            </Row>
                            <Row className={`${styles.users_vert_centered} no-gutters`}>
                                <Col xs="12" className="d-flex justify-content-end">
                                    <div className="mt-auto">
                                        <Link className={`${styles.add_user_btn} btn btn-primary`} to="peergroup" role="button">
                                            Add Group
                                        </Link>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                    <Container className={`${styles.fixed_content} ${styles.disable_scrollbars}`}>
                        <Row>
                            {data.peerGroups.map(peerGroup => <PeerGroupCard key={peerGroup.id} {...peerGroup} onEdit={() => editPeerGroup(peerGroup.id)}
                                onDelete={() => openDeleteModal(peerGroup.id)}/>)}
                        </Row>
                    </Container>
                </Container>
                </>
            )}
        </>
    );
}

export default PeerGroups;