import React, {useState, useContext, Suspense } from "react";
import "@deluxe/unify-styles/index.css";
import "@deluxe/unify-fonts/index.css";
import "@deluxe/unify-button/index.css";
import "@deluxe/unify-dropdown/index.css";
import "@deluxe/unify-icon/index.css";
import "@deluxe/unify-card/index.css";
import "@deluxe/unify-left-navigation/index.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import { UnifyIcon } from "@deluxe/unify-icon";
import "./App.css";
import "../src/Shared/RootLayout/rootLayout.css";
import "./Reports/CustomReports/customReport.css";
//import "./Projections/projections.css";
import {
  createBrowserRouter,
  RouterProvider,
  // createRoutesFromElements,
  // Route,
  // Routes,
  // BrowserRouter,
} from "react-router-dom";
import Projections from "./Projections/projections";
import ProjectionFinancials from "./Projections/projectionFinancials";
import PeerGroups from "./PeerGroups/managePeerGroups";
import PeerGroup from "./PeerGroups/peerGroup";
import Report from "./Reports/Report/report";
import RootLayout from "./Shared/RootLayout/rootLayout";
// import StartPage from "./Shared/RootLayout/StartPage";
import ErrorPage from "./Shared/Components/ErrorPage/404ErrorPage";
import FeatureUnavailable from "./Shared/Components/FeatureUnavailablePage/featureUnavailable";
import {
  hasAccessPrivileges,
  hasAdminPrivileges,
  ACCESS_PRIVILEGES,
  ADMINSTRATIVE_PRIVILEGES,
} from "./Shared/permissions";
import { UserContext } from "./Users/userContext";
import { isPartner } from "./Shared/Utilities/userUtils";
import { TokenContext } from "./Token/tokenContext";
const LazyStartPage=React.lazy(()=>import("./Shared/RootLayout/StartPage"));

function App() {
  const { token, bankId} = useContext(TokenContext); // Access token from TokenContext
  const [wrapperClass, setWrapperClass] = useState("");
  const [errorCode, setErrorCode] = useState(null);
  let featureAccess = null;
  const bankersSession = JSON.parse(sessionStorage.getItem("bankersSession"));
  let user = null;
  const userContext = useContext(UserContext);

  if (userContext.user) {
    user = userContext.user;
    featureAccess = user.features.map(({title}) => title);
  }
 
  const routerList = {
      element: <RootLayout user={user}/>,
      errorElement: <ErrorPage setErrorCode={setErrorCode} setWrapperClass={setWrapperClass} />,
      children:[] // Children are added conditionally based on privs
  };
  // Projection routes
  if(hasAdminPrivileges(user, ADMINSTRATIVE_PRIVILEGES.ProjectionAdmin, bankId)) {
    routerList.children.push(
      {
        path: "/projectionlist",
        element: featureAccess.includes('Projections') ? <Projections /> : <FeatureUnavailable feature="Projection"/>,
        handle: null
      },
      {
        path: "/projection/:bankId/:projectId/data",
        element: <ProjectionFinancials />,
        handle: null
      }
    );
  }
  // User management routes
  /*if(hasPermission(user, PERMISSIONS.UserAdmin)) {
    routerList.children.push(
      {
        path: "/userlist",
        element: <Users />,
        handle: null
      },
      {
        path: "/user",
        element: <User/>,
        handle: null
      },
      {
        path: "/user/:id",
        element: <User/>,
        handle: null
      }
    );
  }*/
  // Peergroup routes
  routerList.children.push(
    {
      path: "/peergroups",
      element: <PeerGroups/>,
      handle: null
    },
    {
      path: "/peergroup",
      element: <PeerGroup/>,
      handle: null
    },
    {
      path: "/peergroup/:id",
      element: <PeerGroup/>,
      handle: null
    }
  );
  if(hasAccessPrivileges(user, ACCESS_PRIVILEGES.GeneralLedger,bankId) && !isPartner()) {
    routerList.children.push(
      {
        path: "/report/:reportType",
        element: <Report/>,
        handle: null
      }
    );
  }
  // default catchall route
  routerList.children.push(
    {
      path: "/*",
      // element: <StartPage/>,
      element: <Suspense fallback={<div>Loading...</div>}><LazyStartPage/></Suspense>,
      handle: null
    }
  );
  const router = createBrowserRouter([routerList]);

    if (token && user && featureAccess) {
    return (
      <RouterProvider router={router}></RouterProvider>
    );
  } else {
    return null;
  }
}
export default App;
